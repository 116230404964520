import React, { useState } from "react";
import "../index.css";
//import { TiTick } from "react-icons/ti";
import { IoIosArrowBack, IoIosCheckmark } from "react-icons/io";
interface StepType {

name:string,

}


const Stepper = ({step,setStep}:{step:number,setStep:Function}) => {
  const steps= [{
    title:"Categoria"
  },{title:"Servizio"}
  ,{title:"Professionista"}
  ,{title:"Data"}
  ,{title:"Dettagli"}]
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  return (
    <>
    {/* Visualizza solo lo step corrente su dispositivi mobili */}
    <div className="md:hidden w-full">
      <div className="flex flex-row bg-white rounded-md shadow-md w-full  items-center border-[#e7bed0] border">
        {/* Visualizza solo lo step corrente */}
        {steps[step] && (
          <div className="flex w-full border-[#e7bed0] p-4 flex-row flex items-center justify-center gap-2">
         
              <div className="h-6 w-6 bg-mamarosa rounded-full flex items-center justify-center"></div>
            
            <div>{steps[step].title}</div>
          </div>
        )}
      </div>
    </div>
  
    {/* Visualizza tutti gli step su schermi più grandi */}
    <div className="hidden md:inline w-full ">
      <div className="flex flex-row bg-white rounded-md shadow-md w-full border-[#e7bed0] border">
        {steps.map((el, index) => (
          <div
            key={index}
            className={`flex-grow border-[#e7bed0] p-4 flex-row flex items-center gap-2 hover:cursor-pointer ${
              index !== steps.length - 1 ? "border-r-2" : ""
            }`}
          >
            {index === step ? (
              <div className="h-6 w-6 bg-mamarosa rounded-full flex items-center justify-center"></div>
            ) : index < step ? (
              <div className="h-4 w-4 bg-mamarosa rounded-full flex items-center justify-center">
                <IoIosCheckmark className="text-white" size={70} />
              </div>
            ) : (
              <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
            )}
            <div>{el.title}</div>
          </div>
        ))}
      </div>
    </div>
  
    {/* Mostra il pulsante "INDIETRO" solo se lo step non è 0 */}
   
      <div className={"flex flex-row w-full rounded-md items-center text-mamarosso font-bold " + (step >0 && step <7?"":"invisible" )}>
        <IoIosArrowBack className="text-mamarosso" />
        <button onClick={() => setStep(step - 1)}>INDIETRO</button>
      </div>
 
  </>
  );
};

export default Stepper;