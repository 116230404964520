  
  
  import React from "react";
  import { IoIosTrash } from "react-icons/io";

  interface CardType {
      //title:string,
      img?:string,
      price?:number,
      name?:string,
      date?:string,
      hour?:string,
      prod_name?:string,
      delete_func?:() => void,
      confirmBook?:boolean

      //description:string,
      
  }
  
  
  export default function CartCard ({el}:{el:CardType}

  ) {
  
  
  return (
  
    <div className="flex items-center space-x-4 p-2 rounded-md bg-white border border-mamarosa">
    <div className="flex-shrink-0">
        <img className="w-16 h-16 rounded-full object-cover" src={el.img} alt="image" />
    </div>
    <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate ">
           {el.name}
        </p>
        <p className="text-sm truncate ">
        {el.prod_name}
        </p>
        {el.date?
        <p className="text-sm font-medium text-gray-900 truncate ">
           {el.date} - {el.hour?.slice(0,-3)}
        </p>:""
  }
    </div>
    <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
       {el.price?el.price +"€":""} 
    </div>
    <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
    {el.price && (!el.confirmBook)?
       <IoIosTrash size={24} className="text-red-500 hover:text-red-700 hover:cursor-pointer" 
       onClick={() => {
        if (el.delete_func) {
          el.delete_func();  // Controllo per verificare se delete_func è definita
        }
      }}
       
       />
       :""}
    </div>
</div>
  
  )
  
  
  
  
  }
  
  
  
  
  
  
  
  
  
