import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "moment/locale/it";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import api from "../api/api";
import { convertDateToInput } from "../utils/convertDateToInput";
import { array, date } from "yup";

const servizi_to_delete = [
  1, 4, 5, 7, 8, 9, 10, 11, 12, 13, 17, 20, 21, 23, 24, 25, 26, 28, 30, 33, 35,
  65, 70, 71, 73,
];
const servizi_to_delete_only_psicoterapeuti = [76];

const id_psicoterapeuti = [31, 61, 82, 87];

export default function Calendar() {
  const { id } = useParams();
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
  const [servizioSel, setServizioSel] = useState<any>(null);
  const [services, setServices] = useState<any>([]);
  const [workingDate, setWorkingDate] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [avaiableSlots, setAvaiableSlots] = useState<any[]>([]); //slots
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [provider, setProvider] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    const res_services = await api.post("/SB/REST", {
      url: "/admin/services",
      method: "get",
    });
    let all_services: any[] = res_services.data.data;
    const validId = id ? parseInt(id) : 0;
    all_services = all_services.filter(
      (el) => servizi_to_delete.indexOf(el.id) == -1
    ); //Rimuovo quelli che devo togliere

    if (id_psicoterapeuti.indexOf(validId) == -1) {
      all_services = all_services.filter(
        (el) => servizi_to_delete_only_psicoterapeuti.indexOf(el.id) == -1
      );
    }
    setServices(
      all_services.filter((el) => {
        return el.providers.indexOf(validId) !== -1;
      })
    );
    setLoading(false);

    const res_provider = await api.post("/SB/REST", {
      url: "/admin/providers/" + id,
      method: "get",
    });

    setProvider(res_provider.data);
  };

  const selectService = async (service: any) => {
    setServizioSel(service);
    setSelectedTime("");
    setAvaiableSlots([]);
    loadMonthAvaiability(
      moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      moment(new Date()).endOf("month").format("YYYY-MM-DD"),
      service.id
    );
  };
  const deSelectService = () => {
    setServizioSel(null);
  };
  const deSelectTime = () => {
    setSelectedTime("");
  };

  const loadMonthAvaiability = async (
    start: string,
    end: string,
    service_id: number
  ) => {
    setLoadingCalendar(true);
    const res_date = await api.post("/SB/REST", {
      url: `/admin/schedule?date_from=${start}&date_to=${end}&provider_id=${id}&service_id=${service_id}`,
      method: "get",
    });
    let dates_temp = res_date.data
      .filter((el: any) => !el.is_day_off)
      .map((el: any) => el.date);
    let dates = [];
    const res_services = [];
    for (let i = 0; i < dates_temp.length; i++) {
      res_services[i] = api.post("/SB/REST", {
        url: "/admin/schedule/available-slots",
        method: "get",
        params: {
          date: convertDateToInput(dates_temp[i]),
          provider_id: id,
          service_id: service_id,
        },
      });
    }
    const results = await Promise.all(res_services);
    for (let i = 0; i < dates_temp.length; i++) {
      if (results[i].data.length != 0) {
        dates.push(dates_temp[i]);
      }
    }
    setWorkingDate(dates);
    setLoadingCalendar(false);
  };

  const loadAppointments = async (date: Date) => {
    setLoadingSlots(true);
    const res_services = await api.post("/SB/REST", {
      url: "/admin/schedule/available-slots",
      method: "get",
      params: {
        date: convertDateToInput(date),
        provider_id: id,
        service_id: servizioSel.id,
      },
    });

    setAvaiableSlots(res_services.data);
    setLoadingSlots(false);
  };

  const handleConfirm = () => {
    navigate("/booking", {
      state: {
        time: selectedTime,
        date: moment(new Date(selectedDate ? selectedDate : "")).format(
          "YYYY-MM-DD"
        ),
        provider: provider,
        servizio: servizioSel.id,
      },
    });
  };

  return (
    <div className="bg-cover h-screen flex-col flex  items-center md:p-16 w-full gap-2 p-4 ">
      <div className="text-mamarosso text-4xl">Verifica la disponibilità</div>
      <div className="flex flex-col gap-2">
        {loading ? <CircularProgress sx={{ color: "#008C95" }} /> : ""}
        {servizioSel == null && !loading && (
          <>
            <div className="text-mamarosso text-xl">Seleziona un servizio:</div>
            {services.map((el: { name: any; id: any }) => {
              return (
                <button
                  className="buttonOttanioChat w-full"
                  onClick={() => selectService(el)}
                >
                  {el.name}
                </button>
              );
            })}
          </>
        )}
      </div>
      {servizioSel && !loading && (
        <>
          <div className="flex flex-row jusitify-center items-center gap-2">
            <div className="buttonOttanioChat relative">
              {servizioSel.name}
              <div
                onClick={deSelectService}
                className="flex justify-center items-center absolute -top-2 -right-2 text-mamarosso bg-mamarosa rounded-full h-6 w-6 hover:cursor-pointer transition-size duration-200  hover:h-8 hover:w-8"
              >
                X
              </div>
            </div>
            {selectedTime !== "" && (
              <div className="buttonOttanioChat relative">
                {selectedTime.slice(0, -3)}
                <div
                  onClick={deSelectTime}
                  className="flex justify-center items-center absolute -top-2 -right-2 text-mamarosso bg-mamarosa rounded-full h-6 w-6 hover:cursor-pointer transition-size duration-200  hover:h-8 hover:w-8"
                >
                  X
                </div>
              </div>
            )}
          </div>
          <div className="bg-white rounded-md border border-mamarosa">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="it"
            >
              <DateCalendar
                disablePast
                onChange={(el) => {
                  setSelectedDate(new Date(el));
                  loadAppointments(new Date(el));
                }}
                onMonthChange={(el) =>
                  loadMonthAvaiability(
                    el.startOf("month").format("YYYY-MM-DD"),
                    el.endOf("month").format("YYYY-MM-DD"),
                    servizioSel.id
                  )
                }
                shouldDisableDate={(d) => {
                  return (
                    workingDate.indexOf(moment(d).format("YYYY-MM-DD")) === -1
                  );
                }}
                loading={loadingCalendar}
                renderLoading={() => (
                  <CircularProgress sx={{ color: "#008C95" }} />
                )}
              />
            </LocalizationProvider>
          </div>
          {selectedTime == "" ? (
            <div className="flex flex-row flex-wrap w-full overflow-hidden  justify-center ">
              {loadingSlots ? (
                <CircularProgress sx={{ color: "#008C95" }} />
              ) : (
                avaiableSlots.map((el) => (
                  <div key={el.id} className="mb-2">
                    <button
                      key={el.id}
                      className="buttonOttanioChat mr-2"
                      onClick={() => {
                        setSelectedTime(el.time);
                        //handleBlockSlot(el);
                      }}
                    >
                      {el.time.slice(0, -3)}
                    </button>
                  </div>
                ))
              )}
            </div>
          ) : (
            <button className="buttonOttanioChat" onClick={handleConfirm}>
              Conferma Appuntamento
            </button>
          )}
        </>
      )}
    </div>
  );
}

/*

 <div className="bg-white rounded-md border border-mamarosa">
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
          <DateCalendar
            disablePast
             onChange={(el) => {
                setSelectedDate(new Date(el));
                loadAppointments(new Date(el));
              }}
              onMonthChange={(el) =>
                loadMonthAvaiability(
                  el.startOf("month").format("YYYY-MM-DD"),
                  el.endOf("month").format("YYYY-MM-DD")
                ) 
              }
                shouldDisableDate={(d) => {
                    return (
                      workingDate.indexOf(moment(d).format("YYYY-MM-DD")) === -1
                    );
                  }}
                loading={loadingCalendar}
                renderLoading={() => <CircularProgress sx={{ color: "#008C95" }} />}
              />
            </LocalizationProvider>
          </div>
*/
