import React from "react";


interface CardType {
    title:string,
    img:string,
    description:string,
    onClick:Function,
    button:boolean,
    price?:number,
    duration?:number,
    circle?:boolean
    
}


export default function Card ({el}:{el:CardType}) {


return (

    <div className="bg-white backdrop-blur-sm rounded-lg flex flex-col p-4 hover:bg-slate-100 hover:cursor-pointer shadow-md border border-mamarosa" onClick={() => el.onClick()}>
    <div className="flex justify-center items-center">{el.circle?<img src={el.img} alt="Placeholder Image" className=" h-48 w-48 rounded-full object-cover object-center" />:<img src={el.img} alt="Placeholder Image" className="w-full h-48 rounded-md object-cover object-center" />}</div>
    <div className="px-1 py-4 flex-grow">
      <div className="font-bold text-xl mb-2 text-mamarosso">{el.title}</div>
      <p className="text-gray-700 text-base">
       {el.description}
      </p>
       {el.price?
      
      <p className="font-bold flex ">
        Durata: {el.duration} min - {el.price} € 
      </p>:"" }
      <div className="flex-grow"></div> 
    </div>

    {el.button?<div className="self-end w-full">  <div className="flex-grow"></div> <button  className='buttonOttanioChat w-full' >Seleziona</button></div>:""}
 
  </div>

)




}

/*


*/