import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import Registration from "./pages/Registration";
import Book from "./pages/Book";
import { Stepper } from "./pages/Stepper";
import Calendar from "./pages/Calendar";
import BookCustom from "./pages/BookCustom";
import ReactGA from "react-ga4";
ReactGA.initialize("G-DKLF5ESSCC");
const router = createBrowserRouter([
  {
    path: "/registration",
    element: <Registration />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/booking",
    element: <Book />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/booking",
    element: <Book />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/booking/terapia",
    element: <BookCustom />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/survey",
    element: <Stepper />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/calendar/:id",
    element: <Calendar />,
    //errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
