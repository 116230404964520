import React, { useState } from "react";
import "../App.css";
import { User } from "../interfaces/User";
import * as yup from "yup";
import api from "../api/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import JSONRpcClient from "../JSONRpcClient";
import logo from "../assets/logo.png";
import sfondo from "../assets/sfondo.jpg";
import { AxiosError } from "axios";

function Registration() {
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [termini, setTermini] = useState<boolean>(false);
  const [checkTermini, setCheckTermini] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const schema = yup.object().shape({
    name: yup.string().required("Campo obbligatorio"),
    cognome: yup.string().required("Campo obbligatorio"),
    email: yup
      .string()
      .email("Inserisci una mail valida")
      .required("Campo obbligatorio"),
    password: yup
      .string()
      .min(8, "La password deve essere di almeno 8 caratteri")
      .required("Campo obbligatorio"),
    phone: yup
      .number()
      .typeError("Attenzione deve essere un numero valido")
      .required("Campo obbligatorio"),
    codice_fiscale: yup
      .string()
      .min(16, "Minimo 16 caratteri")
      .max(16, "Massimo 16 caratteri")
      .matches(
        /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
        "Inserisci un codice fiscale valido!"
      )
      .required(), //una stringa personalizza l'errore,
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Le password devono corrispondere")
      .required("Campo obbligatorio"), //oneOf top per controllare se simili
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], "Le email devono corrispondere")
      .required("Campo obbligatorio"),
    indirizzo: yup.string().required("Campo obbligatorio"),
    cap: yup
      .number()
      .typeError("Attenzione deve essere un cap valido")
      .required("Campo obbligatorio"),
    citta: yup.string().required("Campo obbligatorio"),
    paese: yup.string().required("Campo obbligatorio"),
    prefisso: yup.number().required("Campo obbligatorio"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  //so 2 steps. One create the user then update
  const onSubmit = async (data: User) => {
    // https://user-api-v2.simplybook.me/

    if (!termini) {
      setCheckTermini(true);
      alert("Per proseguire devi accettare i termini e le condizioni");
      return;
    } else {
      setCheckTermini(false);
    }
    if(isLoading){ //check se funzione già lanciata
    return
    }
    //primo step creo
    try {
      setIsLoading(true);
      const result = await api.post("/SB/JSONRP", {
        url: "https://user-api.simplybook.me/",
        method: "signUpClient",
        params: {
          clientData: {
            email: data.email,
            name: data.name + " " + data.cognome,
            phone: "+" + data.prefisso + "" + data.phone,
            password: data.password,
          },
          sendEmail: true,
        },

        //unitId provider
      });

      let user_id: number = result.data.id;

      const res_services = await api.post("/SB/REST", {
        url: `/admin/clients/field-values/${user_id}`,
        method: "put",
        params: {
          id: null,
          fields: [
            {
              id: "name",
              value: data.name + " " + data.cognome,
            },
            {
              id: "email",
              value: data.email,
            },
            {
              id: "address1",
              value: data.indirizzo,
            },
            {
              id: "city",
              value: data.citta,
            },
            {
              id: "country_id",
              value: data.paese,
            },
            {
              id: "zip",
              value: data.cap,
            },
            {
              id: "phone",
              value: "+" + data.prefisso + "" + data.phone,
            },
            {
              id: "a254a76e04893cf37d9a7660373090c0",
              value: privacy,
            },
            {
              id: "16d040bc92c77ac80dcd2e34f4964fb2",
              value: data.codice_fiscale,
            },
            {
              id: "password",
              value: data.password,
            },
          ],
        },
      });

      setUserCreated(true);
      setIsLoading(false);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response && e.response.status === 500) {
          let errorBody = e.response.data.error;

          if (e.response.data.error == "Client with this email already exist") {
            errorBody = "Questa mail risulta già associata ad un altro utente.";
          }
          alert(errorBody);
        } else {
          // Gestione di altri errori
          console.error("Errore durante la chiamata API:", e);
          alert(`Errore: ${e.message}`);
        }
      }
    }
  };

  return (
    <div className={"h-full md:h-screen w-full items-center flex flex-col  "}>
      {userCreated ? (
        <>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md border border-mamarosa">
            <div className="col-span-2 justify-self-center">
              Registrazione avvenuta con successo, controlla la mail!
            </div>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md border border-mamarosa ">
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Nome
                </label>
                <input
                  {...register("name")}
                  type="text"
                  placeholder="Nome"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.name?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Cognome
                </label>
                <input
                  {...register("cognome")}
                  type="text"
                  placeholder="Cognome"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.cognome?.message}</p>
              </div>
            </div>

            <div className="col-span-2 md:col-span-1">
              <div className="grid grid-cols-4 gap-2">
                <div className="mb-5 col-span1">
                  <label
                    htmlFor="fName"
                    className="mb-3  text-base font-medium text-mamarosso"
                  >
                    Prefisso
                  </label>
                  <select
                    {...register("prefisso")}
                    className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                  >
                    <option value="+93">Afghanistan (+93)</option>
                    <option value="+355">Albania (+355)</option>
                    <option value="+213">Algeria (+213)</option>
                    <option value="+1684">Samoa Americane (+1684)</option>
                    <option value="+376">Andorra (+376)</option>
                    <option value="+244">Angola (+244)</option>
                    <option value="+1264">Anguilla (+1264)</option>
                    <option value="+672">Antartide (+672)</option>
                    <option value="+1268">Antigua e Barbuda (+1268)</option>
                    <option value="+54">Argentina (+54)</option>
                    <option value="+374">Armenia (+374)</option>
                    <option value="+297">Aruba (+297)</option>
                    <option value="+61">Australia (+61)</option>
                    <option value="+43">Austria (+43)</option>
                    <option value="+994">Azerbaigian (+994)</option>
                    <option value="+1242">Bahamas (+1242)</option>
                    <option value="+973">Bahrein (+973)</option>
                    <option value="+880">Bangladesh (+880)</option>
                    <option value="+1246">Barbados (+1246)</option>
                    <option value="+375">Bielorussia (+375)</option>
                    <option value="+32">Belgio (+32)</option>
                    <option value="+501">Belize (+501)</option>
                    <option value="+229">Benin (+229)</option>
                    <option value="+1441">Bermuda (+1441)</option>
                    <option value="+975">Bhutan (+975)</option>
                    <option value="+591">Bolivia (+591)</option>
                    <option value="+387">Bosnia ed Erzegovina (+387)</option>
                    <option value="+267">Botswana (+267)</option>
                    <option value="+55">Brasile (+55)</option>
                    <option value="+246">
                      Territori britannici dell'oceano Indiano (+246)
                    </option>
                    <option value="+1284">
                      Isole Vergini britanniche (+1284)
                    </option>
                    <option value="+673">Brunei (+673)</option>
                    <option value="+359">Bulgaria (+359)</option>
                    <option value="+226">Burkina Faso (+226)</option>
                    <option value="+257">Burundi (+257)</option>
                    <option value="+855">Cambogia (+855)</option>
                    <option value="+237">Camerun (+237)</option>
                    <option value="+1">Canada (+1)</option>
                    <option value="+238">Capo Verde (+238)</option>
                    <option value="+1345">Isole Cayman (+1345)</option>
                    <option value="+236">
                      Repubblica Centrafricana (+236)
                    </option>
                    <option value="+235">Ciad (+235)</option>
                    <option value="+56">Cile (+56)</option>
                    <option value="+86">Cina (+86)</option>
                    <option value="+61">Isola di Natale (+61)</option>
                    <option value="+61">Isole Cocos (+61)</option>
                    <option value="+57">Colombia (+57)</option>
                    <option value="+269">Comore (+269)</option>
                    <option value="+682">Isole Cook (+682)</option>
                    <option value="+506">Costa Rica (+506)</option>
                    <option value="+385">Croazia (+385)</option>
                    <option value="+53">Cuba (+53)</option>
                    <option value="+599">Curacao (+599)</option>
                    <option value="+357">Cipro (+357)</option>
                    <option value="+420">Repubblica Ceca (+420)</option>
                    <option value="+243">
                      Repubblica Democratica del Congo (+243)
                    </option>
                    <option value="+45">Danimarca (+45)</option>
                    <option value="+253">Gibuti (+253)</option>
                    <option value="+1767">Dominica (+1767)</option>
                    <option value="+1809">Repubblica Dominicana (+1809)</option>
                    <option value="+670">Timor Est (+670)</option>
                    <option value="+593">Ecuador (+593)</option>
                    <option value="+20">Egitto (+20)</option>
                    <option value="+503">El Salvador (+503)</option>
                    <option value="+240">Guinea Equatoriale (+240)</option>
                    <option value="+291">Eritrea (+291)</option>
                    <option value="+372">Estonia (+372)</option>
                    <option value="+251">Etiopia (+251)</option>
                    <option value="+500">Isole Falkland (+500)</option>
                    <option value="+298">Isole Faroe (+298)</option>
                    <option value="+679">Figi (+679)</option>
                    <option value="+358">Finlandia (+358)</option>
                    <option value="+33">Francia (+33)</option>
                    <option value="+689">Polinesia Francese (+689)</option>
                    <option value="+241">Gabon (+241)</option>
                    <option value="+220">Gambia (+220)</option>
                    <option value="+995">Georgia (+995)</option>
                    <option value="+49">Germania (+49)</option>
                    <option value="+233">Ghana (+233)</option>
                    <option value="+350">Gibilterra (+350)</option>
                    <option value="+30">Grecia (+30)</option>
                    <option value="+299">Groenlandia (+299)</option>
                    <option value="+1473">Grenada (+1473)</option>
                    <option value="+1671">Guam (+1671)</option>
                    <option value="+502">Guatemala (+502)</option>
                    <option value="+441481">Guernsey (+441481)</option>
                    <option value="+224">Guinea (+224)</option>
                    <option value="+245">Guinea-Bissau (+245)</option>
                    <option value="+592">Guyana (+592)</option>
                    <option value="+509">Haiti (+509)</option>
                    <option value="+504">Honduras (+504)</option>
                    <option value="+852">Hong Kong (+852)</option>
                    <option value="+36">Ungheria (+36)</option>
                    <option value="+354">Islanda (+354)</option>
                    <option value="+91">India (+91)</option>
                    <option value="+62">Indonesia (+62)</option>
                    <option value="+98">Iran (+98)</option>
                    <option value="+964">Iraq (+964)</option>
                    <option value="+353">Irlanda (+353)</option>
                    <option value="+441624">Isola di Man (+441624)</option>
                    <option value="+972">Israele (+972)</option>
                    <option value="+39" selected>
                      Italia (+39)
                    </option>
                    <option value="+225">Costa d'Avorio (+225)</option>
                    <option value="+1876">Giamaica (+1876)</option>
                    <option value="+81">Giappone (+81)</option>
                    <option value="+441534">Jersey (+441534)</option>
                    <option value="+962">Giordania (+962)</option>
                    <option value="+7">Kazakistan (+7)</option>
                    <option value="+254">Kenya (+254)</option>
                    <option value="+686">Kiribati (+686)</option>
                    <option value="+383">Kosovo (+383)</option>
                    <option value="+965">Kuwait (+965)</option>
                    <option value="+996">Kirghizistan (+996)</option>
                    <option value="+856">Laos (+856)</option>
                    <option value="+371">Lettonia (+371)</option>
                    <option value="+961">Libano (+961)</option>
                    <option value="+266">Lesotho (+266)</option>
                    <option value="+231">Liberia (+231)</option>
                    <option value="+218">Libia (+218)</option>
                    <option value="+423">Liechtenstein (+423)</option>
                    <option value="+370">Lituania (+370)</option>
                    <option value="+352">Lussemburgo (+352)</option>
                    <option value="+853">Macao (+853)</option>
                    <option value="+389">Macedonia (+389)</option>
                    <option value="+261">Madagascar (+261)</option>
                    <option value="+265">Malawi (+265)</option>
                    <option value="+60">Malesia (+60)</option>
                    <option value="+960">Maldive (+960)</option>
                    <option value="+223">Mali (+223)</option>
                    <option value="+356">Malta (+356)</option>
                    <option value="+692">Isole Marshall (+692)</option>
                    <option value="+222">Mauritania (+222)</option>
                    <option value="+230">Mauritius (+230)</option>
                    <option value="+262">Mayotte (+262)</option>
                    <option value="+52">Messico (+52)</option>
                    <option value="+691">Micronesia (+691)</option>
                    <option value="+373">Moldavia (+373)</option>
                    <option value="+377">Monaco (+377)</option>
                    <option value="+976">Mongolia (+976)</option>
                    <option value="+382">Montenegro (+382)</option>
                    <option value="+1664">Montserrat (+1664)</option>
                    <option value="+212">Marocco (+212)</option>
                    <option value="+258">Mozambico (+258)</option>
                    <option value="+95">Myanmar (+95)</option>
                    <option value="+264">Namibia (+264)</option>
                    <option value="+674">Nauru (+674)</option>
                    <option value="+977">Nepal (+977)</option>
                    <option value="+31">Paesi Bassi (+31)</option>
                    <option value="+687">Nuova Caledonia (+687)</option>
                    <option value="+64">Nuova Zelanda (+64)</option>
                    <option value="+505">Nicaragua (+505)</option>
                    <option value="+227">Niger (+227)</option>
                    <option value="+234">Nigeria (+234)</option>
                    <option value="+683">Niue (+683)</option>
                    <option value="+850">Corea del Nord (+850)</option>
                    <option value="+1670">
                      Isole Marianne Settentrionali (+1670)
                    </option>
                    <option value="+47">Norvegia (+47)</option>
                    <option value="+968">Oman (+968)</option>
                    <option value="+92">Pakistan (+92)</option>
                    <option value="+680">Palau (+680)</option>
                    <option value="+970">Palestina (+970)</option>
                    <option value="+507">Panama (+507)</option>
                    <option value="+675">Papua Nuova Guinea (+675)</option>
                    <option value="+595">Paraguay (+595)</option>
                    <option value="+51">Perù (+51)</option>
                    <option value="+63">Filippine (+63)</option>
                    <option value="+64">Pitcairn (+64)</option>
                    <option value="+48">Polonia (+48)</option>
                    <option value="+351">Portogallo (+351)</option>
                    <option value="+1">Porto Rico (+1)</option>
                    <option value="+974">Qatar (+974)</option>
                    <option value="+242">Repubblica del Congo (+242)</option>
                    <option value="+40">Romania (+40)</option>
                    <option value="+7">Russia (+7)</option>
                    <option value="+250">Ruanda (+250)</option>
                    <option value="+262">Riunione (+262)</option>
                    <option value="+599">Saint Barthelemy (+599)</option>
                    <option value="+290">Sant'Elena (+290)</option>
                    <option value="+1869">Saint Kitts e Nevis (+1869)</option>
                    <option value="+1758">Santa Lucia (+1758)</option>
                    <option value="+590">Saint Martin (+590)</option>
                    <option value="+508">Saint Pierre e Miquelon (+508)</option>
                    <option value="+1784">
                      Saint Vincent e Grenadine (+1784)
                    </option>
                    <option value="+685">Samoa (+685)</option>
                    <option value="+378">San Marino (+378)</option>
                    <option value="+239">Sao Tome e Principe (+239)</option>
                    <option value="+966">Arabia Saudita (+966)</option>
                    <option value="+221">Senegal (+221)</option>
                    <option value="+381">Serbia (+381)</option>
                    <option value="+248">Seychelles (+248)</option>
                    <option value="+232">Sierra Leone (+232)</option>
                    <option value="+65">Singapore (+65)</option>
                    <option value="+1721">Sint Maarten (+1721)</option>
                    <option value="+421">Slovacchia (+421)</option>
                    <option value="+386">Slovenia (+386)</option>
                    <option value="+677">Isole Salomone (+677)</option>
                    <option value="+252">Somalia (+252)</option>
                    <option value="+27">Sudafrica (+27)</option>
                    <option value="+82">Corea del Sud (+82)</option>
                    <option value="+211">Sud Sudan (+211)</option>
                    <option value="+34">Spagna (+34)</option>
                    <option value="+94">Sri Lanka (+94)</option>
                    <option value="+249">Sudan (+249)</option>
                    <option value="+597">Suriname (+597)</option>
                    <option value="+268">Swaziland (+268)</option>
                    <option value="+46">Svezia (+46)</option>
                    <option value="+41">Svizzera (+41)</option>
                    <option value="+963">Siria (+963)</option>
                    <option value="+886">Taiwan (+886)</option>
                    <option value="+992">Tagikistan (+992)</option>
                    <option value="+255">Tanzania (+255)</option>
                    <option value="+66">Thailandia (+66)</option>
                    <option value="+228">Togo (+228)</option>
                    <option value="+690">Tokelau (+690)</option>
                    <option value="+676">Tonga (+676)</option>
                    <option value="+1868">Trinidad e Tobago (+1868)</option>
                    <option value="+216">Tunisia (+216)</option>
                    <option value="+90">Turchia (+90)</option>
                    <option value="+993">Turkmenistan (+993)</option>
                    <option value="+1649">Isole Turks e Caicos (+1649)</option>
                    <option value="+688">Tuvalu (+688)</option>
                    <option value="+1340">
                      Isole Vergini americane (+1340)
                    </option>
                    <option value="+256">Uganda (+256)</option>
                    <option value="+380">Ucraina (+380)</option>
                    <option value="+971">Emirati Arabi Uniti (+971)</option>
                    <option value="+44">Regno Unito (+44)</option>
                    <option value="+1">Stati Uniti (+1)</option>
                    <option value="+598">Uruguay (+598)</option>
                    <option value="+998">Uzbekistan (+998)</option>
                    <option value="+678">Vanuatu (+678)</option>
                    <option value="+379">Vaticano (+379)</option>
                    <option value="+58">Venezuela (+58)</option>
                    <option value="+84">Vietnam (+84)</option>
                    <option value="+681">Wallis e Futuna (+681)</option>
                    <option value="+212">Sahara Occidentale (+212)</option>
                    <option value="+967">Yemen (+967)</option>
                    <option value="+260">Zambia (+260)</option>
                    <option value="+263">Zimbabwe (+263)</option>
                  </select>
                  <p>{errors.paese?.message}</p>
                </div>
                <div className="mb-5 col-span-3">
                  <label
                    htmlFor="fName"
                    className="mb-3  text-base font-medium text-mamarosso"
                  >
                    Telefono
                  </label>
                  <input
                    type="text"
                    {...register("phone")}
                    placeholder="Telefono"
                    className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                  />
                  <p>{errors.phone?.message}</p>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Codice Fiscale
                </label>
                <input
                  {...register("codice_fiscale")}
                  type="text"
                  placeholder="Codice Fiscale"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.codice_fiscale?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Indirizzo
                </label>
                <input
                  {...register("indirizzo")}
                  type="text"
                  placeholder="Indirizzo"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.indirizzo?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  CAP
                </label>
                <input
                  {...register("cap")}
                  type="text"
                  placeholder="CAP"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.cap?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Città
                </label>
                <input
                  {...register("citta")}
                  type="text"
                  placeholder="Città"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.citta?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Paese
                </label>
                <select
                  {...register("paese")}
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                >
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AG">Antigua e Barbuda</option>
                  <option value="SA">Arabia Saudita</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaigian</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrein</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BE">Belgio</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BT">Bhutan</option>
                  <option value="BY">Bielorussia</option>
                  <option value="MM">Birmania</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia ed Erzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BR">Brasile</option>
                  <option value="BN">Brunei</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambogia</option>
                  <option value="CM">Camerun</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Capo Verde</option>
                  <option value="TD">Ciad</option>
                  <option value="CL">Cile</option>
                  <option value="CN">Cina</option>
                  <option value="CY">Cipro</option>
                  <option value="VA">Città del Vaticano</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comore</option>
                  <option value="KP">Corea del Nord</option>
                  <option value="KR">Corea del Sud</option>
                  <option value="CI">Costa d'Avorio</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croazia</option>
                  <option value="CU">Cuba</option>
                  <option value="DK">Danimarca</option>
                  <option value="DM">Dominica</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egitto</option>
                  <option value="SV">El Salvador</option>
                  <option value="AE">Emirati Arabi Uniti</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="SZ">Eswatini</option>
                  <option value="ET">Etiopia</option>
                  <option value="FJ">Figi</option>
                  <option value="PH">Filippine</option>
                  <option value="FI">Finlandia</option>
                  <option value="FR">Francia</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germania</option>
                  <option value="GH">Ghana</option>
                  <option value="JM">Giamaica</option>
                  <option value="JP">Giappone</option>
                  <option value="JO">Giordania</option>
                  <option value="GR">Grecia</option>
                  <option value="GD">Grenada</option>
                  <option value="GT">Guatemala</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GQ">Guinea Equatoriale</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HN">Honduras</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Irlanda</option>
                  <option value="IS">Islanda</option>
                  <option value="IL">Israele</option>
                  <option value="IT" selected>
                    Italia
                  </option>
                  <option value="KZ">Kazakistan</option>
                  <option value="KE">Kenya</option>
                  <option value="KG">Kirghizistan</option>
                  <option value="KI">Kiribati</option>
                  <option value="KW">Kuwait</option>
                  <option value="LA">Laos</option>
                  <option value="LS">Lesotho</option>
                  <option value="LV">Lettonia</option>
                  <option value="LB">Libano</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libia</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lituania</option>
                  <option value="LU">Lussemburgo</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldive</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MA">Marocco</option>
                  <option value="MH">Marshall</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="MX">Messico</option>
                  <option value="FM">Micronesia</option>
                  <option value="MD">Moldavia</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MZ">Mozambico</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NO">Norvegia</option>
                  <option value="NZ">Nuova Zelanda</option>
                  <option value="OM">Oman</option>
                  <option value="NL">Paesi Bassi</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua Nuova Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Perù</option>
                  <option value="PL">Polonia</option>
                  <option value="PT">Portogallo</option>
                  <option value="QA">Qatar</option>
                  <option value="GB">Regno Unito</option>
                  <option value="CF">Repubblica Centrafricana</option>
                  <option value="CZ">Repubblica Ceca</option>
                  <option value="CD">Repubblica Democratica del Congo</option>
                  <option value="DO">Repubblica Dominicana</option>
                  <option value="RO">Romania</option>
                  <option value="RW">Ruanda</option>
                  <option value="RU">Russia</option>
                  <option value="KN">Saint Kitts e Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="VC">Saint Vincent e Grenadine</option>
                  <option value="SB">Salomone</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">São Tomé e Príncipe</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SY">Siria</option>
                  <option value="SK">Slovacchia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SO">Somalia</option>
                  <option value="ES">Spagna</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="US">Stati Uniti d'America</option>
                  <option value="ZA">Sudafrica</option>
                  <option value="SD">Sudan</option>
                  <option value="SS">Sudan del Sud</option>
                  <option value="SE">Svezia</option>
                  <option value="CH">Svizzera</option>
                  <option value="SR">Suriname</option>
                  <option value="TJ">Tagikistan</option>
                  <option value="TZ">Tanzania</option>
                  <option value="TH">Thailandia</option>
                  <option value="TL">Timor Est</option>
                  <option value="TG">Togo</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad e Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turchia</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UA">Ucraina</option>
                  <option value="UG">Uganda</option>
                  <option value="HU">Ungheria</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Vietnam</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                </select>
                <p>{errors.paese?.message}</p>
              </div>
            </div>
            <div className="col-span-2">
              <hr style={{ borderColor: "#e7bed0" }}></hr>
            </div>

            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Email
                </label>
                <input
                  {...register("email")}
                  type="text"
                  placeholder="Email"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.email?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Password
                </label>
                <input
                  {...register("password")}
                  type="password"
                  placeholder="Password"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.password?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Conferma Email
                </label>
                <input
                  {...register("confirmEmail")}
                  type="text"
                  placeholder="Conferma email"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.confirmEmail?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-mamarosso"
                >
                  Conferma Password
                </label>
                <input
                  {...register("confirmPassword")}
                  type="password"
                  placeholder="Conferma Password"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.confirmPassword?.message}</p>
              </div>
            </div>
            <div className="col-span-2 justify-self-left mb-5">
              <div>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className={
                      "form-checkbox h-5 w-5 rounded-full " +
                      (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                    }
                    checked={termini}
                    onClick={() => setTermini(!termini)}
                  />
                  <span
                    className={
                      "ml-2 " +
                      (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                    }
                  >
                    {" "}
                    <a
                      href="https://mamamind.it/condizioni-generali/"
                      target="_blank"
                    >
                      <span className="underline">
                        Acconsento ai termini e condizioni del servizio.*
                      </span>{" "}
                      {checkTermini && " Campo obbligatorio"}
                    </a>
                  </span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-mamaottanio rounded-full"
                    checked={privacy}
                    onClick={() => setPrivacy(!privacy)}
                  />
                  <span className="ml-2 text-mamaottanio underline">
                    <a
                      href="https://mamamind.it/privacy-policy/"
                      target="_blank"
                    >
                      Acconsento a ricevere messaggi di carattere promozionale.
                    </a>
                  </span>
                </label>
              </div>
            </div>

            <div className="col-span-2 justify-self-center ">
              <button type="submit" className="buttonOttanioChat mr-2">
                Registrati
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default Registration;

/* 
  <button onClick={getLogin}>CIOA</button>
 <p>name</p>

            <input className="pl-2 outline-none border-none" {...register("name")} />
            <p>{errors.name?.message}</p>
        
          <p>email</p>
          <input className="pl-2 outline-none border-none" {...register("email")} />
          <p>{errors.email?.message}</p>

          <p>phone</p>
          <input className="pl-2 outline-none border-none" {...register("phone")} />
          <p>{errors.phone?.message}</p>
          <p>Password</p>
          <input className="pl-2 outline-none border-none" {...register("password")} />
          <p>{errors.password?.message}</p>
          <p>CF</p>
          <input className="pl-2 outline-none border-none" {...register("codice_fiscale")} />
          <p>{errors.codice_fiscale?.message}</p>
*/
